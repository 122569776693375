import {
    FolderOutlined,
    DatabaseOutlined,
    TeamOutlined,
    UserOutlined,
    SearchOutlined,
    ScheduleOutlined,
    DollarCircleOutlined,
    FieldTimeOutlined,
    CheckCircleOutlined,
    HourglassOutlined,
    PlusOutlined,
    ReadOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    SwapOutlined,
    BarChartOutlined,
    CopyFilled
} from '@ant-design/icons'

const sidebar = [
    {
        routeName: "frontend.admin.dashboard.index",
        icon: <BarChartOutlined />,
        routeParams: {}
    },
    {
        routeName: "frontend.admin.ordersIndex",
        icon:  <CopyFilled />,
        routeParams: {},
        type: 'sub',
        children: [
            {
                routeName: "frontend.admin.tempOrders.index",
                icon: <HourglassOutlined />,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.orders.index",
                icon: <CheckCircleOutlined /> ,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.orders.create",
                icon: <PlusOutlined /> ,
                routeParams: {}
            }
        ]
    },
    {
        routeName: "frontend.admin.customersIndex",
        icon: <TeamOutlined />,
        type: 'sub',
        routeParams: {},
        children: [{
                title: "Customers",
                routeName: "frontend.admin.customers.index",
                icon: <TeamOutlined/> ,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.customers.booking",
                icon: <ScheduleOutlined/> ,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.schedules.index",
                icon: <FieldTimeOutlined /> ,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.customers.search",
                icon: <SearchOutlined/> ,
                routeParams: {}
            }
        ]
    },
    {
        routeName: "frontend.admin.productsIndex",
        icon: <FolderOutlined />,
        routeParams: {},
        type: 'sub',
        children: [{
                routeName: "frontend.admin.productCategories.index",
                icon: <FolderOutlined/> ,
                routeParams: {},
                permissions: {
                    "admin.productCategories": "R"
                }
            },
            {
                routeName: "frontend.admin.products.index",
                icon: <DatabaseOutlined/> ,
                routeParams: {},
                permissions: {
                    "admin.products": "R"
                }
            },
            {
                routeName: "frontend.admin.products.updateQuantity",
                icon: <SwapOutlined /> ,
                routeParams: {},
                permissions: {
                    "admin.products": "R"
                }
            },
            {
                routeName: "frontend.admin.products.import",
                icon: <ArrowDownOutlined />,
                routeParams: {},
            },
            {
                routeName: "frontend.admin.products.export",
                icon: <ArrowUpOutlined />,
                routeParams: {},
            }
        ]
    },
    {
        routeName: "frontend.admin.usersIndex",
        icon: <UserOutlined/>,
        type: 'sub',
        routeParams: {},
        children: [{
                routeName: "frontend.admin.userGroups.index",
                icon: <TeamOutlined/>,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.users.index",
                icon: <UserOutlined/>,
                routeParams: {}
            },
        ]
    },
    

    {
        routeName: "frontend.admin.revenueIndex",
        icon: <DollarCircleOutlined />,
        routeParams: {},
        type: 'sub',
        children: [{
                routeName: "frontend.admin.revenue.index",
                icon: <DollarCircleOutlined /> ,
                routeParams: {}
            },
            {
                routeName: "frontend.admin.reports.index",
                icon: <ReadOutlined /> ,
                routeParams: {}
            }
        ]
    },
    {
        routeName: "frontend.admin.salariesIndex",
        icon: <DollarCircleOutlined />,
        routeParams: {},
        type: 'sub',
        children: [{
                routeName: "frontend.admin.salaries.index",
                icon: <DollarCircleOutlined /> ,
                routeParams: {}
            },
        ]
    }
]

export default sidebar