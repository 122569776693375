const NextI18Next = require("next-i18next").default;
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();

const DEFAULT_LOCALE = publicRuntimeConfig.LANG || 'en';
const supportedLangs = ['en', 'vi', 'ja'];
// This object allows us to map domain names to locales.
const domainLocaleMap = {
  '127.0.0.1': 'en',
  'localhost': 'vi',
  'mqsolutions.vn': 'vi',
  'mqsolutions.co.jp': 'ja',
  'mq-ict.com.sg': 'en'
};

// Create our custom language detector.
// https://github.com/i18next/i18next-http-middleware#adding-own-detection-functionality
const domainDetector = {
  // We use the name to refer to it later when we want to tell i18next when to use it.
  name: 'domain',
  lookup(req, res, options) {
    let locale = DEFAULT_LOCALE;
    // In the browser, get the hostname from window.location.
    if (typeof window !== 'undefined' ) {
      locale = domainLocaleMap[window.location.hostname];
    } 
    // On the server, get the hostname from the request headers.
    // We use the host header which is available on IncomingMessage.
    // https://nodejs.org/api/http.html#http_class_http_incomingmessage
    // But the host header may include the port so first we take that off, if it exists.
    else {
      let hostname = req.headers.host || '';
      hostname = hostname.split(':')[0];
      // console.log('hostname', hostname)
      locale = domainLocaleMap[hostname];
    }
    return locale;
  }
};

const NextI18NextInstance = new NextI18Next({
  defaultNS: "common",
  defaultLanguage: DEFAULT_LOCALE,
  otherLanguages: supportedLangs,
  localePath: "lang",
  browserLanguageDetection: true,
  serverLanguageDetection: true,
  customDetectors: [domainDetector],
  detection: {
    caches: ["cookie"],
    cookieSameSite: "strict",
    lookupCookie: "next-i18next",
    lookupQuerystring: "lang",
    order: ["domain", "querystring", "cookie", "header"],
  },
});

module.exports = NextI18NextInstance;
